var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('DashboardNavbar',{attrs:{"viewType":_vm.generalSetting.geographicalDistributions.viewType,"viewTypeStatus":true,"activationTypeTokens":_vm.generalSetting.geographicalDistributions.activationTypeTokens,"activationTypesTokensOptions":_vm.activationTypesTokensOptions,"activationTypeStatus":true,"btnAddTitle":_vm.$t('GeographicalDistributions.add'),"btnAddName":'GeographicalDistributionAdd',"btnAddStatus":_vm.checkPrivilege(_vm.hasGeographicalDistributionAdd()),"filterSheetName":'filterSheet',"filterSheetStatus":true},on:{"updateViewType":function($event){return _vm.updateViewType('geographicalDistributions', $event)},"updateActivationTypeTokens":function($event){return _vm.changeActivationType($event)}}}),(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('div',{staticClass:"app-content pagination-with-content"},[_c('GeographicalDistributionTabs'),(_vm.hasData)?[(
          _vm.generalSetting.geographicalDistributions.viewType ==
          _vm.VIEW_TYPES.table
        )?_c('GeographicalDistributionTable',{attrs:{"geographicalDistributionsData":_vm.geographicalDistributions.geographicalDistributionsData,"defaultImg":_vm.geographicalDistributions.geographicalDistribution.defaultImg,"filterData":_vm.geographicalDistributions.filterData},on:{"setGeographicalDistributionData":function($event){return _vm.geographicalDistributions.geographicalDistribution.fillData($event)}}}):(
          _vm.generalSetting.geographicalDistributions.viewType ==
          _vm.VIEW_TYPES.cards
        )?_c('GeographicalDistributionCards',{attrs:{"geographicalDistributionsData":_vm.geographicalDistributions.geographicalDistributionsData,"defaultImg":_vm.geographicalDistributions.geographicalDistribution.defaultImg,"filterData":_vm.geographicalDistributions.filterData},on:{"setGeographicalDistributionData":function($event){return _vm.geographicalDistributions.geographicalDistribution.fillData($event)}}}):_vm._e(),_c('ActionsData',{attrs:{"actionsData":_vm.geographicalDistributions.geographicalDistribution}}),_c('GeographicalDistributionInfo',{attrs:{"geographicalDistribution":_vm.geographicalDistributions.geographicalDistribution}}),_c('GeographicalDistributionDelete',{attrs:{"geographicalDistribution":_vm.geographicalDistributions.geographicalDistribution},on:{"refresh":function($event){return _vm.getAllGeographicalDistributions()}}}),_c('GeographicalDistributionChangeActivationType',{attrs:{"geographicalDistribution":_vm.geographicalDistributions.geographicalDistribution},on:{"refresh":function($event){return _vm.getAllGeographicalDistributions()}}}),_c('CustomBottomSheetQRCode',{attrs:{"refName":'GeographicalDistributionQRCode',"code":_vm.geographicalDistributions.geographicalDistribution.fullCode,"codeTitle":_vm.$t('GeographicalDistributions.code'),"name":_vm.geographicalDistributions.geographicalDistribution
            .geographicalDistributionNameCurrent,"nameTitle":_vm.$t('GeographicalDistributions.name'),"nameIcon":'geographicalDistributions.svg'}})]:_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):_vm._e(),(_vm.hasData)?_c('CustomPagination',{attrs:{"paginationData":_vm.geographicalDistributions.filterData},on:{"changePagination":function($event){return _vm.changePagination($event)}}}):_vm._e(),_c('GeographicalDistributionFilter',{attrs:{"theFilterData":_vm.geographicalDistributions.filterData},on:{"search":function($event){return _vm.search($event)}}}),_c('GeographicalDistributionAdd',{attrs:{"geographicalDistribution":_vm.geographicalDistributions.geographicalDistribution},on:{"refresh":function($event){return _vm.getAllGeographicalDistributions()}}}),_c('GeographicalDistributionUpdate',{attrs:{"geographicalDistribution":_vm.geographicalDistributions.geographicalDistribution},on:{"refresh":function($event){return _vm.getAllGeographicalDistributions()}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }